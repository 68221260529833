<template>
  <div class="text-white">
    <Headline v-if="!hideHeadline" tag="h3" look="h3" weight="medium">
      {{ $t('payment.methods') }}
    </Headline>
    <div class="flex flex-wrap gap-3" :class="{ 'mt-3': !hideHeadline }">
      <PaymentProvider
        v-for="(paymentProvider, index) in paymentProvidersTyped"
        :key="index"
        :payment-provider="paymentProvider"
        :variant="variant"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
defineProps({
  hideHeadline: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  variant: {
    type: String as PropType<'default' | 'small'>,
    default: 'default',
  },
})

const $currentShop = useCurrentShop()
const paymentProvidersTyped = computed(
  () => $currentShop.value.paymentProvidersTyped,
)
</script>
